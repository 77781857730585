<template>
    <div class="policy-cell component flex-row flex-j-between flex-a-center" @click="onClick">
        <div class="flex-1 flex-col flex-j-around flex-a-start">
            <span class="title">{{ item.TitleName }}</span>
            <div class="flex-row flex-j-between flex-a-center" style="width: 100%">
                <span class="time">{{ item.timeText }}</span>
                <span class="time">浏览：{{ item.ViewTimes }}</span>
            </div>
        </div>
        <lazy-img class="image" :src="item.TitleImgUrl"></lazy-img>
    </div>
</template>

<script>
// policy-cell
import LazyImg from "@/components/lazy-img";
export default {
    name: "policy-cell",
    components: {LazyImg},
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/policy-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.policy-cell {
    text-align: left;
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}

.component {
    padding: .20rem;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    font-size: .4rem;
    background-color: #fff;
    border-radius: .12rem;
    margin-bottom: .20rem;
    
}

.component .image {
    width: 3.00rem;
    height: 2.20rem;
    border-radius: .12rem;
    overflow: hidden;
}

.component .flex-1 {
    height: 1.80rem;
    padding-right: .20rem;
}

.component .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #666666;
    font-size: .4rem;
}

.component .time {
    font-size: .36rem;
    color: #999999;
}


.component:active {
    opacity: 0.7;
}
</style>
