<template>
    <div class="policy">
        <left-tab :tabs="tabs" @change="onTabChange" :active="tabsActive">
            <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                <empty-page v-if="!list||list.length===0" msg="暂无相关信息"/>
                <div class="list" style="">
                    <policy-cell v-for="(item, index) in list" :key="index" :item="item"></policy-cell>
                </div>
            </refresh-list>
        </left-tab>
    </div>
</template>

<script>
// policy
import LeftTab from "@/components/left-tab";
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
import PolicyCell from "@/components/policy-cell";
export default {
    name: "policy",
    components: {PolicyCell, EmptyPage, RefreshList, LeftTab},
    data() {
        return {
            tabsActive: 0,
            tabs: [{
                title: "通知公告",
                value: 5
            }, {
                title: "就业政策",
                value: 2,
            },{
                title: "人才政策",
                value: 4
            }],
            list: [],
            pageIndex: 1,
            pageSize: 50,
        }
    },
    mounted() {
        this.loadPageList();
    },
    methods: {
        onTabChange(index) {
            console.log(index)
            this.tabsActive = index;
            this.pageIndex = 1;
            this.list = [];
            this.loadPageList();
        },
        async loadPageList() {
            const params = {
                policyType: this.tabs[this.tabsActive].value,
                sort: "PolicyDate",
                sortType: "desc",
                pageSize: this.pageSize,
                pageIndex: this.pageIndex
            };
            const res = await $app.httpGet({
                path: `/BasePolicyInfo/GetPolicyList`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];

                newList = newList.map(item => {
                    item.timeText = $app.dateFormatChinese(item.PolicyDate);
                    return item;
                });

                if (this.pageIndex === 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.policy {
}
</style>
